import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { ReactComponent as BackIcon} from '../../assets/back.svg';

function BackButton() {
  const navigate = useNavigate();
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(false);

  useEffect(() => {
    // Determine if the current location is not the homepage
    if (location.pathname !== '/' && !location.pathname.startsWith('/reel/') && !location.pathname.startsWith('/auth')) {
      setCanGoBack(true);
    } else {
      setCanGoBack(false);
    }
  }, [location]);

  const handleBack = () => {
    navigate(-1); // Go back one step in history
  };

  if (!canGoBack) return null; // Do not render the button if there's no history to go back

  return (
    <button className='back-button' onClick={handleBack}>
      <BackIcon />
    </button>
  );
}

export default BackButton;