import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import Stories from 'react-insta-stories';
import { motion, useAnimation } from 'framer-motion';
import PullToRefresh from 'pulltorefreshjs';
import { useQuery } from 'react-query';

interface MediaItem {
  file_path: string;
}

interface Story {
  url: string;
  type: 'image' | 'video';
}

function ReelViewPage() {
  const { reelId } = useParams<{ reelId: string }>();
  const navigate = useNavigate();
  const controls = useAnimation();

  // Disable pull-to-refresh when this component mounts
  useEffect(() => {
    PullToRefresh.destroyAll();

    return () => {
      PullToRefresh.init({
        mainElement: 'body',
        iconArrow: '🍆',
        iconRefreshing: '💦',
      });
    };
  }, []);

  // Using react-query to fetch the reel content
  const { data: storyContent, error } = useQuery<Story[]>(['reelContent', reelId], async () => {
    if (!reelId) throw new Error('Invalid reel ID');

    // Fetch all media items for the given reel ID
    const { data: mediaItems, error } = await supabase
      .from('media_items')
      .select('*')
      .eq('reel_id', reelId);

    if (error) throw error;

    const stories = await Promise.all(mediaItems.map(async (item: MediaItem) => {
      const { data, error: signedUrlError } = await supabase.storage
        .from('media_items')
        .createSignedUrl(item.file_path, 60);

      if (signedUrlError) {
        console.error(`Failed to generate signed URL for file: ${item.file_path}`);
        return null;
      }

      const fileUrl = data.signedUrl;
      const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item.file_path);
      const isVideo = /\.(mp4|webm|ogg)$/i.test(item.file_path);

      if (isImage || isVideo) {
        return { url: fileUrl, type: isImage ? 'image' : 'video' };
      }

      return null;
    }));

    return stories.filter((story): story is Story => story !== null);
  });

  const handleClose = () => {
    controls.start({ y: '100vh', opacity: 0 }).then(() => navigate(-1));
  };

  if (!reelId) {
    return <div>Reel ID is missing or invalid</div>;
  }

  if (error) {
    return <div>Failed to load reel content</div>;
  }

  if (!storyContent || storyContent.length === 0) {
    return null;
  }

  return (
    <motion.div
      className='reel'
      initial={{ y: 0, opacity: 1 }}
      animate={controls}
      exit={{ y: '100vh', opacity: 0 }}
      drag="y"
      dragConstraints={{ top: 0, bottom: 0 }}
      onDrag={(event, info) => {
        controls.set({ opacity: 1 - info.point.y / window.innerHeight });
      }}
      onDragEnd={(event, info) => {
        if (info.offset.y > 100) {
          handleClose();
        } else {
          controls.start({ y: 0, opacity: 1 });
        }
      }}
      style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} // Overlay effect
    >
      <Stories
        width='100%'
        height='100dvh'
        stories={storyContent}
        defaultInterval={6000}
        loop={false}
        onAllStoriesEnd={handleClose}
      />
    </motion.div>
  );
}

export default ReelViewPage;