import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from 'react-query';
import PillButton from '../PillButton/PillButton';

// Register FilePond plugins
registerPlugin(FilePondPluginImagePreview);

function CreateReel() {
  const [title, setTitle] = useState('');
  const [mediaFiles, setMediaFiles] = useState<any[]>([]);
  const [isFollowersOnly, setIsFollowersOnly] = useState(false); // New state for "followers only"
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  // Helper function to sanitize filenames
  const sanitizeFileName = (fileName: string) => {
    return fileName.replace(/\s+/g, '_');  // Replace spaces with underscores
  };

  // Mutation for creating a new reel
  const createReelMutation = useMutation(async () => {
    // Check if mediaFiles is not empty
    if (mediaFiles.length === 0) {
      throw new Error('Please upload at least one media file.');
    }

    // Get the current authenticated user
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      throw new Error('You must be logged in to create a reel.');
    }

    // Insert the reel first
    const { data: reelData, error: reelError } = await supabase
      .from('reels')
      .insert({
        title,
        user_id: user.id,
        created_at: new Date().toISOString(),
        is_followers_only: isFollowersOnly, // Set the "followers only" flag
      })
      .select('id')
      .single();

    if (reelError || !reelData) throw reelError;

    // Upload each media file to the reel's media_items bucket
    const reelId = reelData.id;
    for (const file of mediaFiles) {
      // Sanitize the filename before uploading
      const sanitizedFileName = sanitizeFileName(file.file.name);
      const filePath = `${reelId}/${uuidv4()}_${sanitizedFileName}`;

      const { error: uploadError } = await supabase.storage
        .from('media_items')
        .upload(filePath, file.file, {
          cacheControl: '3600',
          upsert: false,
          metadata: {
            user_id: user.id,
            reel_id: reelId,
          }
        });

      if (uploadError) {
        console.error('Error uploading media:', uploadError.message);
        throw new Error('Error uploading one or more media files.');
      }

      // Save media item metadata to the database
      await supabase
        .from('media_items')
        .insert({
          file_name: sanitizedFileName,
          file_path: filePath,
          reel_id: reelId,
          created_at: new Date().toISOString(),
        });
    }
    return reelId;
  });

  const handleCreateReel = () => {
    createReelMutation.mutate(undefined, {
      onSuccess: (reelId) => {
        // Redirect to home page after successful reel creation
        navigate('/');
      },
      onError: (error: any) => {
        setErrorMessage(error.message);
      }
    });
  };

  return (
    <div className='create-reel grid'>
      <div className="col-12">
        <h2>Create a Reel</h2>
      </div>

      <div className="col-12">
        <input
          type="text"
          placeholder="Reel Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className="col-12">
        <FilePond
          files={mediaFiles}
          allowMultiple={true}
          onupdatefiles={setMediaFiles}
          name="media"
          labelIdle='Drag & Drop your media or <span class="filepond--label-action">Browse</span>'
        />
      </div>

      <div className="col-12">
        <label>
          <input
            type="checkbox"
            checked={isFollowersOnly}
            onChange={() => setIsFollowersOnly(!isFollowersOnly)}
          />
          Followers Only
        </label>
      </div>

      <div className="col-12">
        <PillButton onClick={handleCreateReel} disabled={createReelMutation.isLoading}>
          {createReelMutation.isLoading ? 'Creating...' : 'Create'}
        </PillButton>

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
    </div>
  );
}

export default CreateReel;