import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PullToRefresh from 'pulltorefreshjs';
import { supabase } from '../../supabaseClient';
import PillButton from '../PillButton/PillButton';

interface AuthPageProps {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

function AuthPage({ setIsAuthenticated }: AuthPageProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    PullToRefresh.destroyAll();

    return () => {
      PullToRefresh.init({
        mainElement: 'body',
        iconArrow: '🍆',
        iconRefreshing: '💦',
      });
    };
  }, []);

  const handleAuth = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent the default form submission
    setErrorMessage('');
    if (isSignUp && !ageConfirmed) {
      setErrorMessage('You must confirm your age to register.');
      return;
    }

    try {
      if (isSignUp) {
        const { error } = await supabase.auth.signUp({
          email,
          password,
          options: {
            data: {
              username,
              full_name: fullName,
              age_confirmed: ageConfirmed,
            },
          },
        });
        if (error) throw error;

        alert('Sign up successful! Please check your email to confirm.');
      } else {
        const { error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (error) throw error;

        setIsAuthenticated(true);
        navigate('/', { replace: true });
      }
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  return (
    <form className='auth-page grid' onSubmit={handleAuth}>
      <div className="col-12">
        <h2>{isSignUp ? 'Sign up' : 'Login'}</h2>
      </div>

      <div className="col-12">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="col-12">
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      {isSignUp && (
        <>
          <div className="col-12">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="col-12">
            <input
              type="text"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>

          <div className="col-12">
            <input
              className='consent-checkbox'
              type="checkbox"
              checked={ageConfirmed}
              onChange={() => setAgeConfirmed(!ageConfirmed)}
            />
            <label>I confirm that I am over 18</label>
          </div>
        </>
      )}

      {errorMessage && (
        <div className="col-12">
          <p style={{ color: 'red' }}>{errorMessage}</p>
        </div>
      )}

      <div className="auth-actions col-12">
        <PillButton type="submit">{isSignUp ? 'Sign Up' : 'Login'}</PillButton>
        <PillButton onClick={() => setIsSignUp(!isSignUp)}>
          {isSignUp ? 'Switch to Login' : 'Switch to Sign Up'}
        </PillButton>
      </div>
    </form>
  );
}

export default AuthPage;