import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { v4 as uuidv4 } from 'uuid';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import PillButton from '../PillButton/PillButton';

// Register FilePond plugins
registerPlugin(FilePondPluginImagePreview);

interface Profile {
  id: string;
  username: string;
  bio: string | null;
  profile_image: string | null;
  age_confirmed: boolean;
  created_at: string | null;
  updated_at: string | null;
  price: number | null;
  full_name: string | null;
}

interface EditProfilePageProps {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

function EditProfilePage({ setIsAuthenticated }: EditProfilePageProps) {
  const { username } = useParams<{ username: string }>();
  const [newUsername, setNewUsername] = useState('');
  const [bio, setBio] = useState('');
  const [fullName, setFullName] = useState('');
  const [profileImage, setProfileImage] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // Helper function to sanitize filenames
  const sanitizeFileName = (fileName: string) => fileName.replace(/\s+/g, '_');

  // Fetch profile data using react-query
  const { data: profile, isLoading, isError } = useQuery(['profile', username], async () => {
    if (!username) throw new Error('Username is missing.');
    const { data: profileData, error: profileError } = await supabase
      .from('profiles')
      .select('*')
      .eq('username', username as string) // Ensure username is a string
      .single();

    if (profileError) throw profileError;
    return profileData;
  }, {
    onSuccess: (profileData) => {
      setNewUsername(profileData.username);
      setBio(profileData.bio || '');
      setFullName(profileData.full_name || '');
    },
    enabled: !!username, // Only run the query if username is available
  });

  // Update profile mutation
  const updateProfileMutation = useMutation(async (updatedProfile: Partial<Profile>) => {
    if (!profile?.id) throw new Error('Profile ID is not available.');
    const { error: updateError } = await supabase
      .from('profiles')
      .update(updatedProfile)
      .eq('id', profile.id);

    if (updateError) throw updateError;
  }, {
    onSuccess: () => {
      setSuccessMessage('Profile updated successfully!');
      queryClient.invalidateQueries(['profile', username]); // Invalidate the profile query to refresh data
      navigate(`/profile/${newUsername}`);
    },
    onError: (error: any) => {
      setErrorMessage(error.message);
    }
  });

  const handleUpdateProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user || !profile) {
        setErrorMessage('User not authenticated.');
        return;
      }

      // Validate username
      if (newUsername.length < 3) {
        setErrorMessage('Username must be at least 3 characters long.');
        return;
      }

      // Check if the username is taken
      const { data: usernameCheck } = await supabase
        .from('profiles')
        .select('id')
        .eq('username', newUsername)
        .neq('id', profile.id)
        .single();

      if (usernameCheck) {
        setErrorMessage('Username is already taken.');
        return;
      }

      // Prepare the updated profile data
      const updatedProfile: Partial<Profile> = {
        username: newUsername,
        bio,
        full_name: fullName,
      };

      // Handle profile image upload if any
      if (profileImage.length > 0) {
        const file = profileImage[0].file;
        const sanitizedFileName = sanitizeFileName(file.name);
        const filePath = `profile_images/${uuidv4()}_${sanitizedFileName}`;

        const { error: uploadError } = await supabase.storage
          .from('profile_images')
          .upload(filePath, file, {
            cacheControl: '3600',
            upsert: true,
          });

        if (uploadError) {
          throw uploadError;
        }

        // Update profile image path in the database
        updatedProfile.profile_image = filePath;
      }

      // Trigger the update profile mutation
      updateProfileMutation.mutate(updatedProfile);

    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  const handleDeleteProfileImage = async () => {
    try {
      if (!profile?.profile_image) {
        setErrorMessage('No profile image to delete.');
        return;
      }

      const { error: deleteError } = await supabase.storage
        .from('profile_images')
        .remove([profile.profile_image]);

      if (deleteError) throw deleteError;

      // Remove profile image from the database
      await supabase
        .from('profiles')
        .update({ profile_image: null })
        .eq('id', profile.id);

      queryClient.invalidateQueries(['profile', username]); // Invalidate the profile query to refresh data
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  if (isLoading) {
    return <div>Loading profile...</div>;
  }

  if (isError) {
    return <div>Error loading profile</div>;
  }

  return (
    <div className='edit-profile grid'>
      <div className="col-12">
        <h2>Edit Profile</h2>
      </div>

      <div className="col-12">
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      </div>

      <div className="col-12">
        <input
          type="text"
          placeholder="Username"
          value={newUsername}
          onChange={(e) => setNewUsername(e.target.value)}
        />
      </div>

      <div className="col-12">
        <input
          type="text"
          placeholder="Full Name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
      </div>

      <div className="col-12">
        <textarea
          placeholder="Bio"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
        />
      </div>

      <div className="col-12">
        <h3>Profile Image</h3>
      </div>

      <div className="col-12">
        {profile?.profile_image ? (
          <>
            <img
              className='profile-image'
              src={`${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/profile_images/${profile.profile_image}`}
              alt="Profile"
              width="150"
            />
            <PillButton onClick={handleDeleteProfileImage}>🗑️</PillButton>
          </>
        ) : (
          <FilePond
            files={profileImage}
            allowMultiple={false}
            onupdatefiles={setProfileImage}
            name="profile_image"
            labelIdle='Drag & Drop your profile image or <span class="filepond--label-action">Browse</span>'
          />
        )}
      </div>

      <div className="col-12">
        <PillButton onClick={handleUpdateProfile}>Save Changes</PillButton>
      </div>
    </div>
  );
}

export default EditProfilePage;