import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useQuery } from 'react-query';

const ProfileButton: React.FC = () => {
  const navigate = useNavigate();

  // Fetch user profile information using react-query
  const { data: profileData } = useQuery('userProfile', async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return null;

    const { data, error } = await supabase
      .from('profiles')
      .select('profile_image, username')
      .eq('id', user.id)
      .single();

    if (error) throw error;

    const profileImageUrl = data.profile_image
      ? supabase.storage.from('profile_images').getPublicUrl(data.profile_image).data.publicUrl
      : null;

    return {
      username: data.username,
      profileImageUrl,
    };
  });

  const handleProfileClick = () => {
    if (profileData?.username) {
      navigate(`/profile/${profileData.username}`);
    }
  };

  return (
    <div className="profile-button" onClick={handleProfileClick}>
      {profileData?.profileImageUrl ? (
        <img src={profileData.profileImageUrl} alt="Profile" className="profile-image" />
      ) : (
        <div className="profile-placeholder"></div>
      )}
    </div>
  );
};

export default ProfileButton;