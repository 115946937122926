import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PullToRefresh from 'pulltorefreshjs';

import HomePage from './components/HomePage/HomePage';
import ProfilePage from './components/ProfilePage/ProfilePage';
import EditProfilePage from './components/EditProfilePage/EditProfilePage';
import CreateReel from './components/CreateReel/CreateReel';
import ReelViewPage from './components/ReelViewPage/ReelViewPage';
import AuthPage from './components/AuthPage/AuthPage';
import { supabase } from './supabaseClient';
import './App.scss';

import BackButton from './components/BackButton/BackButton';
import Footer from './components/Footer/Footer';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();

  // if we're on iOS in standalone mode, add support for pull to refresh
  //@ts-ignore typescript doesn't recognize the non-standard standalone property as it only exists on iOS
  const isInWebAppIOS = (window.navigator.standalone === true);
  if (isInWebAppIOS){
    PullToRefresh.init({
      mainElement: 'body',
      iconArrow: '🍆', // Use the eggplant emoji for the arrow
      iconRefreshing: '💦', // You can use a different emoji or symbol for the refreshing state
    });
  }

  useEffect(() => {
    const checkSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setIsAuthenticated(!!session);
      setLoading(false);  // Set loading to false after authentication check
    };

    // Initial session check
    checkSession();

    // Listen for authentication state changes
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setIsAuthenticated(!!session);
      setLoading(false);
    });

    // Clean up the listener on unmount
    return () => {
      authListener.subscription?.unsubscribe();
    };
  }, []);

  if (loading) {
    return <div></div>;  // Optionally show a loading state
  }

  return (
    <div className='app-wrapper'>
      <BackButton />
      <Routes>
        <Route path="/auth" element={<AuthPage setIsAuthenticated={setIsAuthenticated} />} />

        {/* Redirect to /auth if not authenticated */}
        <Route path="/" element={isAuthenticated ? <HomePage isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/auth" replace />} />

        <Route path="/profile" element={isAuthenticated ? <ProfilePage setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/auth" replace />} />
        <Route path="/profile/:username" element={isAuthenticated ? <ProfilePage setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/auth" replace />} />
        <Route path="/edit-profile/:username" element={isAuthenticated ? <EditProfilePage setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/auth" replace />} />
        <Route path="/create-reel" element={isAuthenticated ? <CreateReel /> : <Navigate to="/auth" replace />} />
      </Routes>
      <Routes>
        <Route path="/reel/:reelId" element={isAuthenticated ? <ReelViewPage /> : <Navigate to="/auth" replace />} />
      </Routes>

      <Footer noBefore={location.pathname === '/'} />
    </div>
  );
}

export default App;