interface PillButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const PillButton: React.FC<PillButtonProps> = ({ children, onClick, disabled }) => {
  return (
    <button className="pill-button" onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default PillButton;