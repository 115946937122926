import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import ReelTile from '../ReelTile/ReelTile';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import PillButton from '../PillButton/PillButton';

interface ProfilePageProps {
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

function ProfilePage({ setIsAuthenticated }: ProfilePageProps) {
  const { username } = useParams<{ username: string }>();
  const [selectedReelId, setSelectedReelId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: user } = useQuery('user', async () => {
    const { data: { user } } = await supabase.auth.getUser();
    return user;
  });

  const { data: profileData, isLoading: profileLoading } = useQuery(['profile', username], async () => {
    if (!username) throw new Error('Username not provided');
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('username', username)
      .single();
    if (error) throw new Error('Profile not found');
    return data;
  });

  const { data: profileImageUrl } = useQuery(['profileImage', profileData?.profile_image], async () => {
    if (profileData?.profile_image) {
      const { data } = supabase
        .storage
        .from('profile_images')
        .getPublicUrl(profileData.profile_image);
      return data.publicUrl;
    }
    return null;
  }, {
    enabled: !!profileData?.profile_image,
  });

  // Fetch initial follow status
  const { data: isFollowing, refetch: refetchFollowStatus } = useQuery(
    ['followStatus', user?.id, profileData?.id],
    async () => {
      if (!user || !profileData) return false;
      const { data } = await supabase
        .from('follows')
        .select('*')
        .eq('follower_id', user.id)
        .eq('followed_id', profileData.id)
        .single();
      return !!data;
    },
    { enabled: !!user && !!profileData } // Only run this query if user and profileData are available
  );

  const { data: reels, isLoading: reelsLoading } = useQuery(['reels', profileData?.id, isFollowing], async () => {
    if (!profileData?.id) throw new Error('Profile ID not available');

    let query = supabase
      .from('reels')
      .select('*')
      .eq('user_id', profileData.id);

    // If the viewer is not the owner and not following, exclude followers-only reels
    if (user?.id !== profileData.id && !isFollowing) {
      query = query.eq('is_followers_only', false);
    }

    const { data, error } = await query;
    if (error) throw new Error('Failed to fetch reels');
    return data;
  }, {
    enabled: !!profileData?.id && isFollowing !== undefined,
  });

  const followMutation = useMutation(async (action: 'follow' | 'unfollow') => {
    if (!user || !profileData) throw new Error('User or Profile data not available');

    if (action === 'follow') {
      const { error } = await supabase
        .from('follows')
        .insert({ follower_id: user.id, followed_id: profileData.id });
      if (error) throw error;
    } else {
      const { error } = await supabase
        .from('follows')
        .delete()
        .eq('follower_id', user.id)
        .eq('followed_id', profileData.id);
      if (error) throw error;
    }

    queryClient.invalidateQueries(['followStatus', user.id, profileData.id]);
  });

  const handleFollowToggle = () => {
    if (followMutation.isLoading || isFollowing === undefined) return;
    const action = isFollowing ? 'unfollow' : 'follow';
    followMutation.mutate(action, {
      onSuccess: () => {
        // Refetch follow status after mutation success
        refetchFollowStatus();
      }
    });
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsAuthenticated(false);
    navigate('/auth');
  };

  const handleDeleteReel = async (reelId: string) => {
    try {
      if (!profileData?.id) {
        setErrorMessage('Profile ID is not available.');
        return;
      }

      const { data: mediaItems, error: mediaItemsError } = await supabase
        .from('media_items')
        .select('file_path')
        .eq('reel_id', reelId);
      if (mediaItemsError) {
        throw mediaItemsError;
      }

      const filePaths = mediaItems.map(item => item.file_path);
      if (filePaths.length > 0) {
        const { error: storageError } = await supabase.storage
          .from('media_items')
          .remove(filePaths);
        if (storageError) {
          throw storageError;
        }
      }

      const { error } = await supabase
        .from('reels')
        .delete()
        .eq('id', reelId)
        .eq('user_id', profileData.id);
      if (error) throw error;

      queryClient.invalidateQueries(['reels', profileData.id]);
    } catch (error: any) {
      setErrorMessage('Failed to delete reel or associated media items');
    }
  };

  useEffect(() => {
    const handleBackNavigation = () => {
      if (selectedReelId) {
        setSelectedReelId(null);
        navigate(-1);
      }
    };
    window.addEventListener('popstate', handleBackNavigation);

    return () => window.removeEventListener('popstate', handleBackNavigation);
  }, [selectedReelId, navigate]);

  if (profileLoading || reelsLoading) {
    return (
      <div className='profile-photo-wrapper'>
        <div className='profile-photo-container'>
          <div className='profile-photo profile-photo--empty'></div>
        </div>
      </div>
    );
  }

  return (
    <>
      {errorMessage && <p>{errorMessage}</p>}

      {profileData && (
        <>
          <div className='profile-photo-wrapper'>
            <div className='profile-controls'>
              {user?.id === profileData.id && (
                <>
                  <PillButton onClick={() => navigate(`/edit-profile/${profileData.username}`)}>
                    Edit
                  </PillButton>
                  <PillButton onClick={handleLogout}>
                    Logout
                  </PillButton>
              </>
              )}
            </div>

            <div className='profile-photo-container'>
              {profileImageUrl ? (
                <img src={profileImageUrl} className='profile-photo' alt="Profile" />
              ) : (
                <div className='profile-photo profile-photo--empty'></div>
              )}
            </div>
          </div>

          <div className='grid'>
            <div className="col-12">
              <h2>{profileData.username}</h2>
            </div>

            {user?.id !== profileData.id && (
              <div className="col-12">
                  <PillButton onClick={handleFollowToggle}>
                    {isFollowing ? 'Unfollow' : 'Follow'}
                  </PillButton>
              </div>
            )}

            <div className="col-12">
              <p>{profileData.bio || 'No bio available'}</p>
            </div>

            {reels && reels.length > 0 ? (
              reels.map(reel => (
                <div className="col-6" key={reel.id}>
                  <ReelTile
                    reel={reel}
                    hideCreatorInfo={true}
                    showDeleteButton={user?.id === profileData.id}
                    onDelete={handleDeleteReel}
                  />
                </div>
              ))
            ) : (
              <p>No reels available</p>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ProfilePage;