import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import { useQuery } from 'react-query';

interface ReelTileProps {
  reel: {
    id: string;
    title: string | null;
    user_id: string;
  };
  showDeleteButton: boolean;
  hideCreatorInfo?: boolean;
  onDelete?: (reelId: string) => void;
}

const ReelTile: React.FC<ReelTileProps> = ({ reel, showDeleteButton, hideCreatorInfo = false, onDelete }) => {
  const navigate = useNavigate();

  // Fetch thumbnail URL using react-query
  const { data: thumbnailUrl, isLoading: thumbnailLoading } = useQuery(['thumbnail', reel.id], async () => {
    const { data: mediaItems, error: mediaItemsError } = await supabase
      .from('media_items')
      .select('file_path')
      .eq('reel_id', reel.id)
      .limit(1)
      .single();

    if (mediaItemsError) throw mediaItemsError;

    if (mediaItems) {
      const { data, error } = await supabase
        .storage
        .from('media_items')
        .createSignedUrl(mediaItems.file_path, 60);

      if (error) {
        console.error('Error creating signed URL:', error);
        return null;
      } else {
        return data.signedUrl;
      }
    }
    return null;
  });

  // Fetch profile information using react-query
  const { data: profileData, isLoading: profileLoading } = useQuery(['profile', reel.user_id], async () => {
    const { data: profileData, error: profileError } = await supabase
      .from('profiles')
      .select('profile_image, username')
      .eq('id', reel.user_id)
      .single();

    if (profileError) throw profileError;

    const profileImageUrl = profileData.profile_image
      ? supabase.storage.from('profile_images').getPublicUrl(profileData.profile_image).data.publicUrl
      : null;

    return {
      username: profileData.username,
      profileImageUrl,
    };
  });

  // Check ownership using react-query
  const { data: isOwner, isLoading: ownerLoading } = useQuery(['isOwner', reel.user_id], async () => {
    const { data: { user } } = await supabase.auth.getUser();
    return user?.id === reel.user_id;
  });

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent triggering the parent div's onClick
    if (onDelete && reel.id) {
      onDelete(reel.id);
    }
  };

  const handleViewReel = () => {
    navigate(`/reel/${reel.id}`);
  };

  const handleViewProfile = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent triggering the reel view
    if (profileData?.username) {
      navigate(`/profile/${profileData.username}`);
    }
  };

  // if (thumbnailLoading || profileLoading || ownerLoading) {
  //   return <div>Loading...</div>; // Add a loading state to avoid rendering issues
  // }

  return (
    <div className="reel-tile" onClick={handleViewReel}>
      {thumbnailUrl ? (
        <div className="thumbnail">
          <img src={thumbnailUrl} alt={reel.title || 'Reel Thumbnail'} />
        </div>
      ) : (
        <div className="thumbnail placeholder"></div>
      )}
      <div className="reel-info">
        {/* <h4>{reel.title || 'Untitled Reel'}</h4> */}
        {showDeleteButton && isOwner && (
          <button onClick={handleDelete}>🗑️</button>
        )}
      </div>
      {!hideCreatorInfo && profileData && (
        <div className="creator-info" onClick={handleViewProfile}>
          {profileData.profileImageUrl ? (
            <img src={profileData.profileImageUrl} alt={profileData.username || 'User'} className="profile-image" />
          ) : (
            <span className="username-only">{profileData.username}</span>
          )}
          <span className="username">{profileData.username}</span>
        </div>
      )}
    </div>
  );
};

export default ReelTile;