import { useNavigate } from 'react-router-dom';

import { ReactComponent as CreateReelIcon} from '../../assets/plus.svg';

const CreateReelButton: React.FC = () => {
  const navigate = useNavigate();

  const handleCreateReel = () => {
    navigate('/create-reel');
  };

  return (
    <button className="create-reel-button" onClick={handleCreateReel}>
      <CreateReelIcon />
    </button>
  );
};

export default CreateReelButton;