import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../supabaseClient';
import logo from '../../reelfuck.png';
import ReelTile from '../ReelTile/ReelTile';
import { useQuery } from 'react-query';
import ProfileButton from '../ProfileButton/ProfileButton';
import CreateReelButton from '../CreateReelButton/CreateReelButton';

interface HomePageProps {
  isAuthenticated: boolean;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
}

interface Reel {
  id: string;
  title: string | null;
  posted_date: string | null;
  user_id: string;
  is_followers_only: boolean;
  created_at: string | null;
  is_paid: boolean | null;
}

function HomePage({ isAuthenticated, setIsAuthenticated }: HomePageProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const { data: sessionData, isLoading: sessionLoading } = useQuery('session', async () => {
    const { data: { session } } = await supabase.auth.getSession();
    return session;
  });

  const { data: followRelations, isLoading: followsLoading } = useQuery('followRelations', async () => {
    if (!sessionData?.user) return [];
    const { data, error } = await supabase
      .from('follows')
      .select('followed_id')
      .eq('follower_id', sessionData.user.id);
    if (error) throw error;
    return data.map((follow) => follow.followed_id);
  }, {
    enabled: !!sessionData,
  });

  const { data: reelsData, isLoading: reelsLoading } = useQuery('reels', async () => {
    const { data, error } = await supabase
      .from('reels')
      .select('id, title, posted_date, user_id, is_followers_only, created_at, is_paid') // Include all needed fields explicitly
      .order('posted_date', { ascending: false });

    if (error) throw new Error('Failed to load reels');

    // Filter out followers-only reels for users who are not followers
    return (data as Reel[]).filter((reel) => {
      return !reel.is_followers_only || followRelations?.includes(reel.user_id);
    });
  }, {
    enabled: !!sessionData && !followsLoading,
  });

  useEffect(() => {
    if (sessionData) {
      const fetchUsername = async () => {
        try {
          const { data: profileData, error: profileError } = await supabase
            .from('profiles')
            .select('username')
            .eq('id', sessionData.user.id)
            .single();

          if (profileError || !profileData) {
            throw profileError || new Error('Profile data not found');
          }
        } catch (err: any) {
          setErrorMessage(err.message || 'Failed to load user data');
        }
      };

      fetchUsername();
      setIsAuthenticated(true);
    } else if (!sessionLoading) {
      setIsAuthenticated(false);
      navigate('/auth');
    }
  }, [sessionData, sessionLoading, setIsAuthenticated, navigate]);

  if (sessionLoading || reelsLoading || followsLoading) {
    return (
      <div>
        <div className='home-page'>
          <div className='logo-wrapper'>
            <h1 className='visually-hidden'>reelfuck</h1>
            <img src={logo} className='logo' alt="reelfuck logo" />
          </div>

          <div className='grid'>
            <nav className="navigation col-12">
              <CreateReelButton />
              <ProfileButton />
            </nav>
          </div>

          <div className='reel-item reel-item--empty'></div>
        </div>
      </div>
    );
  }

  return (
    <div className='home-page'>
      <div className='logo-wrapper'>
        <h1 className='visually-hidden'>reelfuck</h1>
        <img src={logo} className='logo' alt="reelfuck logo" />
      </div>

      <div className='grid'>
        <nav className="navigation col-12">
          <CreateReelButton />
          <ProfileButton />
        </nav>
      </div>

      {errorMessage ? (
        <p style={{ color: 'red' }}>{errorMessage}</p>
      ) : (
        <>
          {reelsData && reelsData.length > 0 ? (
            reelsData.map(({ id, title, user_id }) => (
              <div className='reel-item' key={id}>
                <ReelTile
                  reel={{ id, title, user_id }}
                  showDeleteButton={false}
                />
              </div>
            ))
          ) : (
            <p>No reels available.</p>
          )}
        </>
      )}
    </div>
  );
}

export default HomePage;