import { useLocation } from "react-router-dom";

interface FooterProps {
  noBefore?: boolean;
}

const Footer: React.FC<FooterProps> = ({ noBefore }) => {
  const location = useLocation();

  return (
    <>
      {!location.pathname.startsWith('/reel/') && (
        <div className={noBefore ? 'footer footer--no-before' : 'footer'}>
          reelfuck nudity transmission system v0.1
        </div>
      )}
    </>
  );
}

export default Footer;